import { pickBy } from 'lodash'
import { valueIsDefined } from '@netpurpose/utils'
import {
  PortfolioSnapshot as PortfolioSnapshotBE,
  PortfolioWithCoverage,
  PortfolioWithEntityDetail,
} from '../../generated/facts'
import { ReverseFieldMap } from '../../queryBuilder'
import { Camelize } from '../../utils'
import { formatDateForBackend, parseDateFromBackend } from '../datetime'

export type PortfolioType = PortfolioWithCoverage['type']

export type Portfolio = Omit<
  Camelize<PortfolioWithCoverage>,
  'portfolioId' | 'valuationDate' | 'totalValue' | 'valPctCovered' | 'valPctResearched' | 'funds'
> & {
  id: number
  valuationDate: Date
  value?: number
  valueCovered?: number
  valueResearched?: number
  funds?: Portfolio[]
}

export const transformPortfolio = <PortfolioProps extends Camelize<PortfolioWithCoverage>>({
  portfolioId,
  snapshotId,
  totalValue,
  valPctCovered,
  valPctResearched,
  valuationDate,
  funds,
  ...rest
}: PortfolioProps): Portfolio => ({
  ...pickBy(
    { value: totalValue, snapshotId, funds: funds?.map(transformPortfolio) },
    valueIsDefined,
  ),
  id: portfolioId,
  valueCovered: valPctCovered,
  valueResearched: valPctResearched,
  valuationDate: parseDateFromBackend(valuationDate),
  ...rest,
})

export const reversePortfolioFieldMap: ReverseFieldMap<keyof Portfolio> = {
  id: 'portfolio_id',
  snapshotId: 'snapshot_id',
  name: 'name',
  type: 'type',
  valuationDate: {
    key: 'valuation_date',
    transform: (date) => (date ? formatDateForBackend(date) : undefined),
  },
  value: 'total_value',
  valueCovered: 'val_pct_covered',
  valueResearched: 'val_pct_researched',
  numHoldings: 'num_holdings',
  numHoldingsCovered: 'num_holdings_covered',
  numHoldingsResearched: 'num_holdings_researched',
  funds: 'funds',
}

export type EntityPortfolio = Omit<
  Camelize<PortfolioWithEntityDetail>,
  'portfolioId' | 'valuationDate' | 'totalValue'
> & {
  id: number
  valuationDate: Date
  portfolioValue?: number
}

export const transformEntityPortfolio = <
  EntityPortfolioProps extends Camelize<PortfolioWithEntityDetail>,
>({
  portfolioId,
  snapshotId,
  totalValue,
  entityValue,
  valuationDate,
  ...rest
}: EntityPortfolioProps): EntityPortfolio => ({
  ...pickBy({ portfolioValue: totalValue, entityValue, snapshotId }, valueIsDefined),
  id: portfolioId,
  valuationDate: parseDateFromBackend(valuationDate),
  ...rest,
})

export const reverseEntityPortfolioFieldMap: ReverseFieldMap<keyof EntityPortfolio | 'searchTerm'> =
  {
    id: 'portfolio_id',
    snapshotId: 'snapshot_id',
    name: 'name',
    type: 'type',
    valuationDate: {
      key: 'valuation_date',
      transform: (date) => (date ? formatDateForBackend(date) : undefined),
    },
    portfolioValue: 'total_value',
    entityValue: 'entity_value',
    entityWeight: 'entity_weight',
    searchTerm: 'name',
  }

export type PortfolioSnapshot = Camelize<PortfolioSnapshotBE>

export const reversePortfolioSnapshotFieldMap: ReverseFieldMap<keyof PortfolioSnapshot> = {
  snapshotId: 'snapshot_id',
  valuationDate: 'valuation_date',
  totalValue: 'total_value',
  createdAt: 'created_at',
  createdBy: 'created_by',
  isLatest: 'is_latest',
  numHoldings: 'num_holdings',
  totalWeight: 'total_weight',
}
